import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Typography,
} from "@mui/material";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {Dropdown} from "../../dropdown/Dropdown.jsx";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import jwtDecode from "jwt-decode";
import {AuditLogTable} from "../../auditLogTable/AuditLogTable.jsx";
import {createAuditLogEntry, RI_AUDIT_LOG_API} from "../../../helpers/createAuditLogEntry.js";
import {Info} from "@mui/icons-material";

const FUNCTION_NAMES = [
    {
        name: `ilab-data-processor-stage-startAirflowDAG`,
        link: 'https://us-west-2.console.aws.amazon.com/lambda/home?region=us-west-2#/functions/ilab-data-processor-stage-startAirflowDAG',
        profile: 'portal'
    },
    {
        name: `ilab-data-processor-stage-eventScheduling`,
        link: 'https://us-west-2.console.aws.amazon.com/lambda/home?region=us-west-2#/functions/ilab-data-processor-stage-eventScheduling',
        profile: 'portal'
    },
    {
        name: 'ScorecardsV2SpinsCmsRefre-RefreshCmsSpinsCompanyBr-vfThwrbeTpAM',
        link: "https://us-west-2.console.aws.amazon.com/lambda/home?region=us-west-2#/functions/ScorecardsV2SpinsCmsRefre-RefreshCmsSpinsCompanyBr-vfThwrbeTpAM",
        profile: 'destini'
    }
].map((fn) =>
    process.env.REACT_APP_ENV === "prod" ?
        fn :
        {
            name: fn.name + '-dev',
            link: fn.link + '-dev',
            profile: fn.profile
        }
);

export const REQUEST_ENDPOINT = `${process.env.REACT_APP_RIS_API_URL}/retail-intelligence/lambda`;

const lambdaDefaultPayload = (lambdaName) => {
    return lambdaName.toString().includes("startAirflowDAG") ? {
        release_type: "INNOVATION_DATABASE_UPDATE",
        release_data: true,
        force_execution: true,
    } : {}
}

export const AwsLambda = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [currentSelection, setCurrentSelection] = useState(FUNCTION_NAMES[0]);
    const [jsonData, setJsonData] = useState(lambdaDefaultPayload(currentSelection.name));

    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);

    const pre = document.querySelector(`pre`);
    const auth0AccessToken = window.localStorage.getItem("access_token");

    const userEmail = auth0AccessToken
        ? jwtDecode(auth0AccessToken)["https://aot/email"]
        : "";

    useEffect(() => {
        setIsLoading(true);
        setJsonData(lambdaDefaultPayload(currentSelection.name));
        setIsLoading(false);
        setIsEdit(false);
    }, [currentSelection]);

    const handleReset = () => {
        document.querySelector(`pre`).innerHTML = JSON.stringify(
            lambdaDefaultPayload(currentSelection.name),
            undefined,
            2
        );
    };

    const handleInvoke = () => {
        setIsLoading(true);
        setJsonData(JSON.parse(pre.textContent));
        return axios
            .post(
                `${REQUEST_ENDPOINT}/${currentSelection.name}`,
                {payload: pre.textContent, profile: currentSelection.profile},
                {headers: {authorization: `Bearer ${auth0AccessToken}`}}
            )
            .then(async () => {
                toast.success("Invoke successful!");
                await createAuditLogEntry(RI_AUDIT_LOG_API, {
                    lambda: currentSelection.name,
                    payload: JSON.parse(pre.textContent),
                });
                setIsEdit(false);
            })
            .catch((error) => {
                toast.error(`${error}`);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <ToastContainer/>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "90%",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <Typography
                    variant={"h4"}
                    sx={{marginTop: "8px", marginBottom: "16px"}}
                >
                    AWS Lambda Function
                </Typography>
                <Dropdown
                    label={`Function Name`}
                    data={FUNCTION_NAMES.map((fn) => {
                        return {
                            value: fn,
                            displayName: fn.name,
                        };
                    })}
                    sx={{
                        marginBottom: "16px",
                        width: "100%",
                        maxWidth: "1080px",
                    }}
                    currentValue={currentSelection}
                    onChange={(e) => setCurrentSelection(e.target.value)}
                    // styles={'score-card-combobox'}
                />
                <Box sx={{
                    display: 'flex',
                    gap: '8px',
                    margin: '0 0 16px'
                }}>
                    <Info aria-describedby={currentSelection.name} data-testid={`info-${currentSelection.name}`}/>
                    <span>
						Lambda URL: <a href={currentSelection.link} target="_blank"
                                       data-testid={'lambda-url'}> {currentSelection.name} </a>
					</span>
                </Box>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        maxWidth: "1080px",
                    }}
                >
                    {isLoading ? (
                        <CircularProgress
                            sx={{position: "relative", left: "50%"}}
                        />
                    ) : (
                        <>
                            <Paper
                                sx={{
                                    backgroundColor: "#f1f1f1",
                                    width: "80%",
                                }}
                            >
								<pre
                                    contentEditable={isEdit}
                                    data-testid="pre-element"
                                    style={{
                                        color: !isEdit ? "#767676" : "initial",
                                    }}
                                    data-value={jsonData}
                                >
									{JSON.stringify(jsonData, undefined, 2)}
								</pre>
                            </Paper>
                            <div
                                style={{
                                    width: "20%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                }}
                            >
                                <Button
                                    style={{marginLeft: 25}}
                                    variant="contained"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsEdit(!isEdit);
                                    }}
                                >
                                    EDIT
                                </Button>
                                <Button
                                    style={{marginLeft: 25}}
                                    variant="contained"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleReset();
                                    }}
                                >
                                    RESET
                                </Button>
                                <Button
                                    style={{marginLeft: 25}}
                                    variant="contained"
                                    color="warning"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOpen(true);
                                    }}
                                >
                                    INVOKE
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                <AuditLogTable
                    endpoint={RI_AUDIT_LOG_API}
                    params={{lambda: currentSelection}}
                />
            </Box>
            {open && (
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    data-testid={"dialog-box"}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        style={{fontSize: "17px"}}
                    >
                        {
                            "Are you sure you want to execute this Lambda function?"
                        }
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                void handleInvoke();
                                setOpen(false);
                            }}
                            autoFocus
                            data-testid={"confirm-save"}
                        >
                            Yes
                        </Button>
                        <Button onClick={() => setOpen(false)}>No</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
